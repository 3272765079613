import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Loader.css';

const PortfolioLoader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 50); // Adjust the speed of the loader here

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loader-container">
      <motion.div 
        className="loader-content"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="loader-header">
          <h2 className="loader-title">Loading Portfolio</h2>
        </div>
        <div className="progress-bar">
          <motion.div 
            className="progress"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="progress-text">
          {progress}%
        </div>
      </motion.div>
    </div>
  );
};

export default PortfolioLoader;